.pull-down-action {
    position: absolute;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: white;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    z-index: 99;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transition: opacity 0.4s ease;
    background: white;
    box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
    opacity: 0;
    top: 50px;
}
.pull-down-action.progress {
    opacity: 0.5;
}
.pull-down-action.action {
    opacity: 1;
}
.pull-down-action svg {
    width: 34px;
    height: 100%;
    fill: red
}
.pull-down-action-inner-wrapper {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    animation-name: roll;
    animation-duration: 0.9s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes roll {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}