.home__board-list-item {
  &__container {
    position: relative;
    height: 80px;
    width: 600px;
    margin-bottom: 16px;

    border: 2px solid transparent;
    border-radius: 8px;

    background-size: cover;
    background-position: center;

    &:hover {
      cursor: pointer;
    }
    &:active {
      opacity: 0.5;
    }
  }
  &__label {
    position: absolute;
    align-items: center;
    display: flex;
    justify-content: flex-start;

    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    align-self: flex-start;
    min-width: 200px;

    color: #ffffff;
    font-size: 20px;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    user-select: none;
  }
}
